import { Routes } from '@angular/router';
import { PageNotFoundComponent } from "./modules/shared/page-not-found/page-not-found.component";


export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/business/business.module').then(m => m.BusinessModule) , 
    // canActivateChild: [AuthGuard]
  },
  {
    path: 'athlete/:userId',
    loadChildren: () => import('./modules/athlete/athlete.module').then(m => m.AthleteModule) , 
    // canActivateChild: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule) , 
    // canActivateChild: [AuthGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent
  }
];
